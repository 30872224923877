<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='800px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>{{ title }} medicine</div>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-sm fluid>
          <v-layout row wrap>
            <v-flex sm12>
              <v-autocomplete
                :disabled='dlgType > 0'
                label='Medicine:'
                :items='drugs'
                item-text='Name'
                item-value='Id'
                v-model='dlgModel.DrugId'
              />
            </v-flex>
            <v-flex sm12>
              <v-text-field
                v-model.trim='dlgModel.Quantity'
                name='fQuantity'
                label='Quantity (Units):'
                :disabled='dlgType > 1'
                v-validate='{ required: true }'
                :error='!!errors.has("fQuantity")'
                :error-messages='errors.first("fQuantity")'
              />
            </v-flex>

            <v-flex sm12>
              <v-text-field
                v-model.trim='dlgModel.Dosage'
                name='fDosage'
                label='Dosage:'
                :disabled='dlgType > 1'
                v-validate='{ required: true }'
                :error='!!errors.has("fDosage")'
                :error-messages='errors.first("fDosage")'
              />
            </v-flex>

            <v-flex sm12
              ><div v-show='submit.error'>
                <v-list two-line>
                  <v-list-item>
                    <span class='red--text'>{{ submit.message }}</span>
                  </v-list-item>
                </v-list>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          :disabled='dlgType === 2'
          color='primary darken-1'
          text
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import drugsPrescriptionApi from '../../api/drugsPrescriptionApi';
export default {
  props: {
    dlgShow: {
      type: Boolean,
      default: false
    },
    dlgType: {
      type: Number,
      default: 2
    },
    item: Object
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dlgModel: {},
      medicineIntakeAtATime: '',
      medFrequency: '',
      foodType: -1,
      time: [],
      form: [
        'CAP',
        'TAB',
        'Powder',
        'Syrup',
        'Cream',
        'Ointment',
        'Injectable'
      ],
      food: [
        { Id: 1, Name: 'Doesnt apply' },
        { Id: 2, Name: 'Before Food' },
        { Id: 3, Name: 'After Food' }
      ],
      frequency: [
        'Daily',
        '3 times / week',
        'Once in 3 days',
        'Once in a Week',
        'Once in 15 days',
        'Once in month',
        'Once in 3 months',
        'Once in 6 months',
        'Once in a year'
      ]
    };
  },
  watch: {
    item: function() {
      if (this.dlgType === 0) {
        this.dlgModel = {
          Strength: '-',
          Dosage: '',
          Quantity: '',
          Form: '-',
          DrugId: -1
        };
      } else {
        this.dlgModel = {
          Strength: '-',
          Dosage: this.item.Dosage,
          Quantity: this.item.Quantity,
          Form: '-',
          DrugId: this.item.DrugId
        };
      }
    }
  },
  computed: {
    drugs: {
      get() {
        return this.$store.getters['DrugsStore/Drugs'];
      }
    },
    title() {
      return this.dlgType === 0
        ? 'Create'
        : this.dlgType === 1
        ? 'Edit'
        : 'View';
    }
  },
  methods: {
    // updateDosage() {
    //   let str = '';
    //   str += this.medicineIntakeAtATime;
    //   str += ' ' + this.dlgModel.Form;
    //   str += ' ' + this.medFrequency;
    //   if (this.foodType === 2) {
    //     str += ' before food';
    //   } else if (this.foodType === 3) {
    //     str += ' after food';
    //   }
    //   if (this.time.length > 0) {
    //     for (let i = 0; i < this.time.length; i++) {
    //       str += ' ' + this.time[i];
    //     }
    //   }
    //   this.dlgModel.Dosage = str;
    // },
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {};
      this.$emit('close');
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      if (this.dlgType === 0) {
        this.dlgModel.Form = '-';
        this.dlgModel.Strength = '-';
        this.api_create(this.dlgModel);
      } else {
        const newItem = {
          Id: this.item.Id,
          Dosage: this.dlgModel.Dosage,
          Quantity: this.dlgModel.Quantity,
          Form: '-',
          Strength: '-'
        };
        this.api_update(newItem);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await drugsPrescriptionApi.add(data);
      this.submit.working = false;
      if (res.Success) {
        this.$notify({
          type: 'info',
          text: 'Successfully added new medicine prescription'
        });
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      this.submit.working = true;
      const res = await drugsPrescriptionApi.update(data);
      this.submit.working = false;
      if (res.Success) {
        this.$notify({
          type: 'info',
          text: 'Successfully updated the medicine prescription'
        });
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
